$lightcolor: #fafafa;
$pulpocolor: #035e7b;
$pulpocolor-disabled: #035e7b4d;

.pulpo-dialog-container .mat-dialog-container {
    padding: 0%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: scroll !important;
}

md-icon {
    font-family: 'Material Icons' !important;
}

.cursor-grab {
    cursor: grab;
}

// ************************************************************************************************************************
// *** OVERRIDING THE STYLES OF OUR MAT-FORM-FIELDS TO MAKE IT LOOKS LIKE LEGACY INPUTS THAT WE HAD IN ANGULAR MATERIAL 14
// ************************************************************************************************************************

.mdc-text-field--filled,
.mat-mdc-text-field-wrapper:hover,
.mat-mdc-form-field-focus-overlay {
    background-color: transparent !important;
}

.mdc-text-field {
    padding: 0 !important;
}

.mdc-text-field {
    height: 50px !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
}

.mdc-snackbar__label {
    padding: 0 !important;
}

.table-wrapper {
    align-items: flex-start;
    max-height: calc(60vw - 155px); // or height:calc(100vh - 155px); depending on your need  change
    overflow: auto;

    .pulpo-table {
        th.mat-header-cell,
            th.mat-mdc-header-cell {
            font-size: 16px;
            background-color: var(--pulpo-color);
            color: white;
            font-weight: bold;
        }

        tr.mat-header-row,
        th.mat-mdc-header-row{
            height: 45px;
        }

        tr.mat-footer-row,
        tr.mat-row {
            max-height: 2.5vw !important;
        }

        tr.row-land-only {
            background-color: rgba(185, 199, 210, 0.77) !important;
        }

        tr.row-catalogue {
            background-color: rgba(185, 199, 210, 0.77) !important;
        }
    }

    button {
        outline: none;
        border: none;
    }
}

.table-header {
    background: var(--pulpo-color) !important;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 0 20px;
}

.filters {
    label {
        color: $lightcolor !important;
    }

    ::placeholder {
        color: $lightcolor !important;
    }

    mat-form-field {
        color: $lightcolor !important;

        * {
            color: $lightcolor !important;
        }

        .mat-form-field-underline {
            background-color: $lightcolor !important;
        }

        .mat-form-field-infix {
            border-bottom: 1px solid $lightcolor !important;
        }

        .mat-form-field-ripple {
            background-color: $lightcolor !important;
        }

        .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before {
            background-color: $lightcolor !important;
            border-bottom-color: $lightcolor !important;
        }
    }

    mat-datepicker-toggle,
    select {
        font-size: 16px;
        color: $lightcolor !important;
    }
}

.mat-error {
    color: #f44336 !important;
}

.w-85 {
    width: 85% !important;
}

.w-25 {
    width: 25%;
}

.w-35 {
    width: 35%;
}

.w-45 {
    width: 45%;
}

.w-20 {
    width: 20%;
}

.w-15 {
    width: 15%;
}

.w-55 {
    width: 55%;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-auto {
    width: auto;
}

.min-vw-0 {
    min-width: 0 !important;
}

.text-right {
    text-align: right;
}

.scale-2 {
    transform: scale(2);
}

.background,
.background-hover,
.background-hover:focus {
    background-color: $pulpocolor !important;
    color: white;
}

.row {
    padding: 0 0 0 0;
    margin: 0 0 0 0;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-weight: 300 !important;
    font-family: system-ui, sans-serif !important;
}

.snackbar {
    min-width: 30vw !important;
    width: 30vw !important;
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    background-color: transparent !important;
    padding: 0 !important;
}

.update-snackbar {
    min-width: 30vw !important;
    width: 30vw !important;
    position: absolute !important;
    bottom: 20px !important;
    left: 20px !important;
    background-color: transparent !important;
    padding: 0 !important;
}

.btn-pulpo:disabled {
    background-color: var(--pulpo-color-disabled) !important;
    color: rgb(255, 255, 255) !important;
    outline: none !important;
    border: none !important;
}

.btn-pulpo {
    background-color: var(--pulpo-color) !important;
    color: white !important;
    outline: none !important;
    border: none !important;
}

.btn-pulpo-red {
    background-color: red !important;
    color: white !important;
    outline: none !important;
    border: none !important;
}

.btn-pulpo-red:hover {
    background-color: rgba(255, 0, 0, 0.7) !important;
}

.btn-pulpo:hover:not([disabled]) {
    background-color: rgba(0, 136, 181, 0.7) !important;
}


.btn-sized {
    width: 150px !important;
    height: 45px !important;
    border-radius: 10px !important;
}


.mat-loading .mat-button-wrapper {
    visibility: hidden;
}

.mat-loading .mat-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

.colored {
    color: rgba(3, 94, 123, 1) !important;
}

.red-colored {
    color: red !important;
}

.green-colored {
    color: green !important;
}

.yellow-colored {
    color: yellow !important;
}

.orange-colored {
    color: orange !important;
}

.transparent-colored {
    opacity: 0 !important;
}

.pulpo-background-extra-light {
    background-color: #5fd4ff4d;
}

.spinner-box {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(3px);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
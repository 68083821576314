/* You can add global styles to this file, and also import other style files */

// Variables

:root {
  --admin-color: #800069;
  --admin-color-light: rgba(128, 0, 105, 0.5);
  --light: white;
  --pulpo-color: var(--admin-color);
}

// Elements

html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

// Importing Bootstrap SCSS file.

@import "bootstrap/scss/bootstrap";

@import 'codemirror/lib/codemirror';
@import 'codemirror/theme/material';

// Custom classes

.corners {
  border-radius: 5px;
}

.bordered {
  border: 1px solid var(--admin-color);
}

.pointer {
  cursor: pointer;
}

// Width

.w-10 {
  width: 10%;
}

.w-12 {
  width: 12%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-26 {
  width: 26%;
}

.w-30 {
  width: 30%;
}

.w-33 {
  width: 33%;
}

.admin-button {
  background-color: white;
  color: var(--admin-color);
  border: var(--admin-color) solid 2px !important;
  border-radius: 5px !important;
  padding: 5px;
  margin: 5px;
}

// Lists of data (tables)

.table-list {
  th,
  .table-header {
    background-color: var(--admin-color) !important;
    color: var(--light) !important;
    font-weight: bold;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      display: flex;
      align-items: center;

      .mat-icon {
        transform: scale(2);
      }
    }
  }

  .mat-table {
    .buttons-container {
      display: flex;
      justify-content: space-evenly;
    }
  }

  .mat-paginator-sticky {
    bottom: 0px;
    position: sticky;
  }

    .mat-sort-header-container {
      text-align: center;
      justify-content: center;
    }
}

// Filters

.filter-panel {
  background-color: var(--admin-color);
  color: var(--light);

  .id-field {
    margin-left: 35px;
    margin-right: -24px;
  }

  .mat-form-field {
    width: 75%;

    input {
      caret-color: var(--light);
    }
  }
}


// Dialog boxes

.edit-dialog {
  height: auto;
  .admin {
    background: var(--admin-color);
    color: white;
  }

  .delete {
    background: red;
    color: white;
  }

  .force-delete {
    background: red;
    box-shadow: 10px 5px 5px darkred;
    color: white;
  }

  .bordered {
    padding: 10px;

    mat-label {
      color: var(--admin-color);
    }
  }
}

.background,
.background-hover,
.background-hover:focus {
  background-color: var(--admin-color) !important;
  color: white;
}

.bg-blue {
  background-color: rgb(100, 100, 208) !important;
}
.bg-green {
  background-color: #00a65a !important;
}

.bg-orange {
  background-color: #f39c12 !important;
}

.bg-orange-soft {
  background-color: #f5bcb6 !important;
}

.bg-green-soft {
  background-color: #d1f2eb !important;
}